import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import Layout from '../components/Layout/Layout'
import LastPosts from '../components/Posts/LastPosts'
import Seo from '../components/Seo/Seo'
import StickyBox from '../components/StickyBox/StickyBox'
import { ArrowLeft, ArrowRight, ArticleContent, AuthorAvatar, AuthorDescription, AuthorName, AuthorWrapper, ChangePost, ChangePostWrapper, Content, Date, FeaturedImage, NNContactForm, Title, UpdateDate } from './post.style'

const Post = ({ pageContext }) => {
  const post = pageContext.data
  const next = pageContext.next
  const previous = pageContext.previous
  const image = getImage(post.featuredImage?.node?.localFile)
  const nnWidgetArticles = [
    'dodatkowe-ubezpieczenie-emerytalne-to-nie-opcja-to-koniecznosc',
    'zwrot-skladki-a-ubezpieczenie-na-zycie-kredytu-hipotecznego-w-nn-i-pzu',
    'prudential-komfort-zycia-a-nationale-nederlanden-ochrona-jutra-na-ktora-ochrone-postawic',
    'prudential-oszczednosci-bez-obaw-a-nationale-nederlanden-sposob-na-przyszlosc-ktory-sposob-na-oszczedzanie',
    'prudential-emerytura-bez-obaw-a-nationale-nederlanden-sposob-na-przyszlosc-gdzie-po-wyzsza-emeryture',
    'twoj-plan-aviva-a-sposob-na-przyszlosc-nationale-nederlanden-na-co-sie-zdecydowac',
    'aviva-nowa-perspektywa-czy-ochrona-jutra-nationale-nederlanden-co-wybrac',
    'twoje-zycie-aviva-a-sposob-na-przyszlosc-nationale-nederlanden-porownanie-polis'
  ]
  return (
    <Layout>
      <Seo title={post.seo.title} description={post.seo.metaDesc} keywords={post.seo.focuskw} article={true} pathname={`/blog/${post.slug}/`} image={image.images?.fallback?.src}/>
      <Container>
        <Row>
          <Col lg={8}>
            <Title>{post.title}</Title>
            <Date>
              {post.dateGmt}
              <UpdateDate>{post.modifiedGmt}</UpdateDate>
            </Date>
          </Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col lg={8}>
            <FeaturedImage>
              <GatsbyImage image={image} alt={post.title} title={post.title} />
            </FeaturedImage>
            <ArticleContent>
              <Content dangerouslySetInnerHTML={{ __html: post.content }} />
              <AuthorWrapper>
                <Row>
                  <Col md={3}>
                    <AuthorAvatar
                      src={post.author.node?.avatar?.url}
                      alt="Avatar"
                    />
                  </Col>
                  <Col md={9}>
                    <AuthorName to={`/autor/${post.author?.node?.slug}/`}>{post.author?.node?.seo?.title}</AuthorName>
                    <AuthorDescription>
                      {post.author?.node?.description}
                    </AuthorDescription>
                  </Col>
                </Row>
              </AuthorWrapper>
              <ChangePostWrapper>
                {previous && (
                  <ChangePost to={`/blog/${previous.slug}`}>
                    <span>POPRZEDNI</span>
                    <ArrowLeft>{previous.title}</ArrowLeft>
                  </ChangePost>
                )}
                {next && (
                  <ChangePost to={`/blog/${next.slug}`}>
                    <span>NASTĘPNY</span>
                    <ArrowRight>{next.title}</ArrowRight>
                  </ChangePost>
                )}
              </ChangePostWrapper>
            </ArticleContent>
          </Col>
          <Col lg={4}>
            {nnWidgetArticles.includes(post.slug) && (
              <NNContactForm>
                <iframe src="https://lp.nn.pl/leadguru" width="100%" height="660" scrolling="no"></iframe>
              </NNContactForm>
            )}
            <StickyBox />
          </Col>
        </Row>
        <Row>
          <Col>
            <LastPosts />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Post
